<template lang="pug">
div
  h4.text-left {{ $t('backOffice') }}
  Tabs(:tabs="tabs")
  router-view
</template>

<script>
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'BackOffice',
  components: {
    Tabs: () => import('@/components/atoms/Tabs')
  },
  data () {
    return {
      tabs: [
        { id: 1, link: { name: 'coefficients-backoffice' }, label: 'ntzCoefficient', checkAccess: () => checkAccess('menuItem-etiRatio') },
        { id: 2, link: { name: 'courses-backoffice' }, label: 'coursesETI', checkAccess: () => checkAccess('menuItem-etiCourse') },
        { id: 3, link: { name: 'list-eti-backoffice' }, label: 'listETI', checkAccess: () => checkAccess('menuItem-etiInstitution') },
        // { id: 4, link: { name: 'price-course-backoffice' }, label: 'coursePriceETI', checkAccess: () => checkAccess('menuItem-priceEtiCourse') },
        // { id: 5, link: { name: 'rate-eti-backoffice' }, label: 'priceETI', checkAccess: () => checkAccess('menuItem-pricePacket') },
        { id: 6, link: { name: 'dealing-backoffice' }, label: 'dealingETI', checkAccess: () => checkAccess('menuItem-etiDealing') },
        { id: 7, link: { name: 'crewing-backoffice' }, label: 'crewing', checkAccess: () => checkAccess('menuItem-crewingCompanies') },
        { id: 8, link: { name: 'vnz-backoffice' }, label: 'vnz', checkAccess: () => checkAccess('menuItem-vnz') },
        { id: 9, link: { name: 'medical-backoffice' }, label: 'medical', checkAccess: () => checkAccess('menuItem-medical') },
        { id: 10, link: { name: 'auto-signing-distribution-backoffice' }, label: 'signingDistribution', checkAccess: () => checkAccess('menuItem-auto-signing-distribution') },
        { id: 12, link: { name: 'translate-agencies-backoffice' }, label: 'translationAgency', checkAccess: () => checkAccess('menuItem-translate-agencies') },
        { id: 13, link: { name: 'mes-agencies-backoffice' }, label: 'mesAgency', checkAccess: () => checkAccess('menuItem-mes-agencies') },
        { id: 14, link: { name: 'powers-of-attorney-backoffice' }, label: 'powersOfAttorney', checkAccess: () => checkAccess('menuItem-powers-of-attorney') },
        { id: 15, link: { name: 'payment-statement-nostrification-backoffice' }, label: 'PriceStatementNostrification', checkAccess: () => checkAccess('payment-statement-nostrification') },
        { id: 16, link: { name: 'directory-backoffice', params: { ...this.$route.params, dictionaryType: this.$route.params.dictionaryType || 'LevelQualification' } }, label: 'directory', checkAccess: () => checkAccess('backoffice') }
      ]
    }
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'backOffice', access: checkAccess('menuItem-backOffice') })
  }
}
</script>

<style lang="sass">
  @import '../../assets/sass/seafarer/main'
</style>
